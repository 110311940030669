@tailwind base;
@tailwind components;
@tailwind utilities;

// Button hover/ripple override:

.mat-mdc-unelevated-button.mat-unthemed {
  --mat-mdc-button-persistent-ripple-color: #5E9925;
  --mat-mdc-button-ripple-color: rgba(94, 153, 27, 0.06);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #5E9925;
  --mat-mdc-button-ripple-color: rgba(94, 153, 27, 0.06);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: rgba(239, 239, 239, 0.3);
}


// Radio box color
.mdc-radio__inner-circle {
  border-color: #5E9925 !important;
}

.mdc-label {
  cursor: pointer;
}

.custom-interdiction-tooltip {
  .mdc-tooltip__surface {
    &.mdc-tooltip__surface-animation {
      min-width: 300px;
      background:  #26abd3 !important;
      padding: 10px;
    }
  }
}


.custom-delivery-info-tooltip {
  .mdc-tooltip__surface {
    &.mdc-tooltip__surface-animation {
      min-width: 300px;
      background:  #f9ffea !important;
      color: #474747 !important;
      padding: 15px;
      line-height: 1.5;
      font-size: 15px;
      border-radius: 5px;
      box-shadow: 0.81px 0.81px 4.88px 0 rgba(0, 0, 0, 0.2);
      font-family: Roboto, "Helvetica Neue", sans-serif;
    }
  }
}

// Material Modal max width in mobile
.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: 92vw !important; // default was 80vw
}
.cdk-overlay-pane {
  max-width: 92vw !important;
}

// Mat Tabs component
.mat-mdc-tab-label-container {
  overflow-x: scroll !important; // this makes it scrollable
}

.mat-mdc-tab-label-container::-webkit-scrollbar {
  display: none;
}

// To fix the buggy mat label in the input animation when first loads
@keyframes cdk-text-field-autofill-start {/*!*/}
@keyframes cdk-text-field-autofill-end {/*!*/}